import React from "react"

import { Layout } from "../components"
import "./agreement.css"
import "./main.css"

const OkautoBotOffer = () => {
  return (
    <Layout>
      <div className="agreement__wrapper lh-copy animation">
        <h1 className="f1-l f2-m f3">Публичная оферта</h1>
        <p>
          Товарищество с ограниченной ответственностью «OKauto», именуемое в
          дальнейшем «OKauto», предлагает физическим лицам, использующим сервис
          OKauto использующим чат-бот @OKauto, именуемым в дальнейшим
          «Пользователь» воспользоваться сервисом OKauto, далее совместно
          именуемые «Стороны».
        </p>
        <br />
        <p>
          Настоящее предложение в соответствии со статьей 395 Гражданского
          Кодекса Республики Казахстан является публичной офертой (далее –
          Пользовательское соглашение), полным и безоговорочным принятием
          (акцептом) условий которой в соответствии со статьей 396 Гражданского
          кодекса Республики Казахстан считается осуществление Пользователем
          конклюдентных действий – использование сервиса OKauto и/или
          осуществление оплата услуг, оказываемых OKauto, в соответствии с
          тарифами OKauto.
        </p>

        <h2 className="f2-l f3-m f4"> Термины и определения</h2>

        <p>
          <ul>
            <li>
              Мессенджер WhatsApp – программа, позволяющая обмениваться
              сообщениями и медиафайлами разных форматов;
            </li>
            <li>
              Чат-бот @OKauto – автоматизированная программа, работающая в
              мессенджере WhatsApp, с которой Пользователь может
              взаимодействовать, общаться для получения информационных услуг,
              оплачивать штрафы и получать иные услуги (далее по тексту
              чат-бот);
            </li>
            <li>
              Сбор Персональных данных – действия, направленные на получение
              Персональных данных;
            </li>
            <li>
              Обработка Персональных данных – действия, направленные на
              накопление, хранение, изменение, дополнение, использование,
              распространение, обезличивание, блокирование и уничтожение
              Персональных данных.
            </li>
          </ul>
        </p>
        <h2 className="f2-l f3-m f4"> Основные положения:</h2>
        <p>
          1. Совершая действия по акцепту Пользовательского соглашения,
          Пользователь подтверждает свою правоспособность и дееспособность, а
          также своё законное право вступать в договорные отношения.
        </p>
        <p>
          2. Акцепт Пользовательского соглашения равносилен заключению
          Пользовательского соглашения и всех приложений к нему и означает, что
          Пользователь согласен со всеми положениями настоящего предложения. В
          связи с вышеизложенным, внимательно прочитайте текст Пользовательского
          соглашения и Приложений к нему (Условий, размещенных в чат-боте). Если
          Вы не согласны с каким-либо пунктом Пользовательского соглашения,
          OKauto предлагает Вам отказаться от Акцепта Пользовательского
          соглашения.
        </p>
        <p>
          3. Предмет и условия Пользовательского соглашения устанавливаются в
          соответствующем Приложении к нему (Условия, размещенные в чат-боте).
          Приложения являются неотъемлемой частью Пользовательского соглашения.
          Все условия, содержащиеся в Приложениях к Пользовательскому
          соглашению, являются Условиями Пользовательского соглашения.
        </p>
        <h2 className="f2-l f3-m f4"> Использование сервиса OKauto:</h2>
        <p>
          4. Пользователь согласно данным, отображенным в чат-боте, оплачивает
          следующее вознаграждение OKauto:
          <ol>
            <li>стоимость подписки.</li>
          </ol>
        </p>
        <p>
          5. Пользователь оплачивает вознаграждение OKauto указанное в п. 4
          Пользовательского соглашения. Данная сумма является невозвратной и не
          подлежит перерасчету за неполный месяц использования чат-бота.
        </p>
        <p>
          6. Пользователь дает согласие на автоматическое списание денег за
          подписку.
        </p>
        <p>
          OKauto имеет право отказать в предоставлении услуг, заблокировать
          Пользователя в следующих случаях:
          <ul>
            <li>Не оплаты предоставляемого сервиса OKauto;</li>
            <li>
              нарушение Пользователем законодательства Республики Казахстан;
              Условий, размещённых в чат-боте;
            </li>
            <li>
              совершение мошеннических действий, попыток выполнить платёж по
              украденным данным банковских карт, недобросовестных манипуляций;
            </li>
            <li>и в иных случаях</li>
          </ul>
        </p>
        <h2 className="f2-l f3-m f4">
          Регистрация на сайте, конфиденциальность и защита персональных данных:
        </h2>
        <p>
          8. Пользователь предоставляет OKauto согласие на Сбор Персональных
          данных и Обработку Персональных данных, передачу третьим лицам в целях
          предоставления сервиса Пользователям и согласен с тем, что ему могут
          направляться письма и сообщения, пуш уведомления, в том числе
          рекламного характера.
        </p>
        <p>
          9. OKauto использует персональные данные Пользователя в том числе:
          <ul>
            <li>для предоставления информационных услуг;</li>
            <li>для выполнения своих обязательств перед Пользователем;</li>
            <li>для оценки и анализа работы чат-бота;</li>
            <li>для определения победителей в конкурсах;</li>
            <li>для проведения анализа и измерения и т.д.</li>
          </ul>
        </p>
        <p>
          10. Не считается нарушением предоставление OKauto персональных данных
          в соответствии с обоснованными и применимыми требованиями закона,
          агентам и третьим лицам, для исполнения обязательств перед
          Пользователем и предоставления сервиса OKauto. Данная информация не
          используется для установления личности Пользователя, за исключением
          случаев мошеннических действий Пользователя.
        </p>
        <p>
          11. Пользователь несёт ответственность за достоверность передаваемых
          OKauto персональных данных.
        </p>
        <p>
          12. Совершая действия по подключению сервиса привязки банковской
          карты, Пользователь подтверждает свое согласие на хранение информации
          третьими лицами, нести самостоятельную полную ответственность за
          использование сервиса привязки банковской карты; все риски, связанные
          с возможным использованием третьими лицами логина, пароля, ответа на
          контрольный вопрос, номера карты, использование автоматической
          авторизации, в том числе при пересылке сообщения, полученного на
          электронную почту и иной информации.
        </p>
        <p>
          13. Пользователь даёт согласие OKauto на запись телефонных разговоров
          для повышения качества обслуживания и подтверждения устных заявлений
          Пользователя.
        </p>
        <p>
          14. Пользователь даёт свое согласие на получение обновлённой
          информации, информационных сообщений с последними новостями, новыми
          предложениями, специальными предложениями и объявлениями о продаже, а
          также информации о новостях и предложениях Партнеров OKauto
          посредством SMS, электронной почты, а также пуш-уведомлений,
          телефонных звонков и других способов коммуникации.
        </p>
        <h2 className="f2-l f3-m f4">Прочие условия:</h2>
        <p>
          15. OKauto имеет право в любой момент изменять условия
          Пользовательского соглашения в одностороннем порядке без
          предварительного согласования с Пользователями, обеспечивая при этом
          публикацию измененных условий в чат-боте.
        </p>
        <p>
          16. OKauto не несёт ответственности по возможным рискам получения
          информационных и иных услуг оказываемых OKauto, (в том числе, но не
          ограничиваясь), сбои в работе чат-бота, вызванные техническими
          причинами, технологических неисправностей каналов связи общего
          пользования, мессенджера WhatsApp, посредством которых осуществляется
          доступ к сервису, или утраты доступа к сети Интернет — до устранения
          неисправностей или восстановления доступа, соответственно. Наличия
          признаков несанкционированного доступа к управлению аккаунта (канала в
          WhatsApp) — на срок действия таких обстоятельств.
        </p>
        <p>
          17. Стороны освобождаются от ответственности за неисполнение или
          ненадлежащее исполнение обязательств по Пользовательскому соглашению
          на время действия непреодолимой силы. Под непреодолимой силой
          понимаются чрезвычайные и непреодолимые обстоятельства, препятствующие
          исполнению своих обязательств Сторонами по Пользовательскому
          соглашению. К ним относятся локдауны и введение иных ограничительных и
          запретительных мер государственными органами; стихийные явления;
          воздействие ядерного взрыва, радиации (ионизирующего излучения) или
          радиоактивного заражения материалами ядерного оружия, производственных
          и научно-исследовательских работ или радиоактивными отходами; войны,
          военные вторжения, враждебные действия иностранного врага, военные
          манёвры и связанные с ними мероприятия военного характера; гражданская
          война, вооружённый мятеж, народные волнения всякого рода, массовые
          беспорядки, насильственный захват или насильственное удержание власти;
          эпидемии, метеоусловия, террористические действия, забастовки,
          изъятия, конфискации, национализация и т.п. При этом общеизвестные
          события не нуждаются в каком-либо подтверждении.
        </p>
        <p>
          18. Все споры и разногласия, вытекающие из правоотношений по
          Пользовательскому соглашению, разрешаются путём переговоров с
          обязательным соблюдением претензионного порядка. Срок ответа на
          претензию составляет 10 (десять) календарных дней.
        </p>
        <p>
          19. Любые споры и/или разногласия, возникающие из Пользовательского
          соглашения или в связи с ним (в том числе касающиеся его толкования,
          исполнения, нарушения, прекращения или недействительности), подлежат
          урегулированию в суде по месту нахождения OKauto, в соответствии с
          законодательством Республики Казахстан.
        </p>
        <p>
          20. Стороны пришли к соглашению о том, что Пользовательское соглашение
          считается заключенным с момента использования сервиса OKauto и/или
          осуществление оплата услуг, оказываемых OKauto, не требует
          двустороннего подписания и действительно в электронном виде.
        </p>
        <p>
          21. Изображения и тексты, опубликованные в чат-боте защищены
          авторскими правами. Любое использование материалов возможно только с
          письменного разрешения OKauto.
        </p>
        <p>
          22. Данная версия Пользовательского соглашения является текущей и
          заменяет все предыдущие.
        </p>
        <p>
          23. Пользователь имеет право обратиться к OKauto или в суд за защитой
          своих нарушенных прав и законных интересов. Контактные данные (адреса
          и номера телефона) уполномоченного органа и субъектов досудебного
          урегулирования{" "}
          <a href="https://www.gov.kz/memleket/entities/mti-kzpp/contacts?lang=ru.">
            потребительских споров
          </a>
        </p>
        <p>
          В случае возникновения вопросов, Пользователь может обратиться к
          OKauto по адресу: Республика Казахстан, город Алматы, улица Мынбаева,
          53, 3 этаж, 4 офис. Телефон: +7 777 444 14 14.
        </p>
      </div>
    </Layout>
  )
}

export default OkautoBotOffer
